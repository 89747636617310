import React, { useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import Hero from '../hero/Hero'
import CardReview from '../cards/CardReview';
import { ContainerLayout } from '../../themes/Layouts';
import Separator from '../section/Separator';
import ContentHtmlBodegas from '../section/ContentHtmlBodegas';
import ContactBodegas from '../section/ContactBodegas';
import SliderBodegas from '../slider/SliderBodegas';
import SEO from '../SEO';
import ReactGA from "react-ga4";

const PageBodegaRender = ({ title, imgbg, review, content, contentimg, contactData, slideData, slug, seo }) => {

    useEffect(() => {
        ReactGA.send({
            hitType: "pageview",
            page: `/bodegas/${slug}`,
            title: title 
        });
    }, [slug, title]);

    if(!title || !imgbg) {
        return <div>Loading..</div>;
    }

    const cardVariants = {
        hidden: { opacity: 0, y: 50 }, // Aparece de abajo
        visible: { opacity: 1, y: 0 }, // Se coloca en la posición final
    };

    return (
        <>
            <SEO
                title={`Vino Buenos Aires | ${title}`}
                description={seo.description}
                name="Vino Buenos Aires."
                type="website"
            />

            <Hero    
                imgbg={imgbg }
                title={title}
                />

            { review.active && 
                <section className="mb-[6rem] md:mb-[10rem]">
                    <ContainerLayout className="grid grid-cols-4 md:grid-cols-12 mb-[4rem]">
                        <div className="col-span-4 md:col-span-10 md:col-start-2">
                            <CardReview review={review}/>
                        </div>
                    </ContainerLayout>
                    <Separator/>
                </section>
            }

            <ContainerLayout className="grid grid-cols-4 md:grid-cols-12 gap-[4rem] mb-[4rem] md:mb-[6rem]">
                <motion.img
                    className='col-span-4 md:col-span-6 order-none md:order-1 rounded-[0.4rem]'
                    src={`/images/bodegas/${contentimg}`}
                    alt={`foto de bodega ${title}`}
                    variants={cardVariants}
                    initial="hidden"
                    whileInView="visible" // Se activa la animación cuando entra en vista
                    viewport={{ once: false, amount: 0.2 }} // Controla cuándo se inicia la animación, amount=20%
                    transition={{ duration: 0.8 }} // Duración de la animación
                />
                <motion.div
                    className='col-span-4 md:col-span-6'
                    variants={cardVariants}
                    initial="hidden"
                    whileInView="visible" // Se activa la animación cuando entra en vista
                    viewport={{ once: false, amount: 0.2 }} // Controla cuándo se inicia la animación, amount=20%
                    transition={{ duration: 0.8 }} // Duración de la animación
                >
                    <ContentHtmlBodegas content={content} />
                </motion.div>
            </ContainerLayout>

            <section className='mb-[4rem] md:mb-[10rem]'>
                <SliderBodegas slideData={slideData} />
            </section>

            <ContactBodegas contactData={contactData}/>

        </>
    )
}

export default PageBodegaRender