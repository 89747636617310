import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import tw from 'twin.macro';
import styled from 'styled-components';
import { ContainerLayout } from '../../themes/Layouts';

const BackGround = tw.div`fixed top-0 left-0 z-30 w-full h-[6.2rem] md:h-[8.3rem] bg-[#F0F4DB] border-b-[1px] border-[#C9B066]`;
const Container = tw(ContainerLayout)`hidden md:flex justify-between items-center`;
const ContainerLinks = tw.ul`md:flex gap-[1rem] lg:gap-[5rem] hidden`;
const LinkNav = tw.li`
    text-[1.7rem] text-[#7F5B29] font-[500] transition-colors ease-in-out duration-300
    hover:text-[#B47D02] hover:underline
`;

const ContainerMobile = tw(ContainerLayout)`flex justify-between items-center md:hidden`;
const ButtonMobile = tw.button``;

const MenuMobile = styled.div(({ isOpen }) => [
    tw`fixed top-0 left-0 w-full pt-[6.2rem] bg-[#F0F4DB] md:hidden rounded-b-[1rem] shadow-sm transform duration-300 z-20`,
    isOpen ? tw`translate-y-0` : tw`-translate-y-full`,
]);

const NavBar = () => {
    const [menuOpen, setMenuOpen] = useState(false); // Estado para controlar si el menú está abierto o cerrado

    const toggleMenu = () => {
        setMenuOpen(!menuOpen); // Alterna el estado de abierto/cerrado
    };

    return (
        <>
            <BackGround>
                <Container>
                    <Link to="/"><img src="/images/logo-nav.svg" className='md:w-full' alt="logo vinos buenos aires" /></Link>
                    <ContainerLinks>
                        <LinkNav><Link to="/legislacion">Ley - Legislación</Link></LinkNav> 
                        <LinkNav><Link to="/bodegas">Bodegas</Link></LinkNav> 
                        <LinkNav><Link to="/noticias">Noticias</Link></LinkNav> 
                    </ContainerLinks>
                </Container>
                <ContainerMobile>
                    <Link to="/"><img src="/images/logo-nav-mobile.svg" className='h-full' alt="logo vinos buenos aires" /></Link>
                    <ButtonMobile onClick={toggleMenu}>
                        <img src={menuOpen ? '/images/menu-close.svg' : '/images/menu.svg'} alt="Menu Toggle" />
                    </ButtonMobile>
                </ContainerMobile>
            </BackGround>
            <MenuMobile isOpen={menuOpen}> {/* Cambié 'isopen' a 'isOpen' */}
                <ContainerLayout>
                    <ul className='flex flex-col text-center font-semibold text-[1.7rem] px-[1.5rem] py-[3rem] justify-between'>
                        <li className='flex items-center justify-center text-[#7F5B29] h-[4.5rem] border-b-[1px] border-[#C9B066]'>
                            <Link to="/" onClick={toggleMenu}>Inicio</Link>
                        </li> 
                        <li className='flex items-center justify-center text-[#7F5B29] h-[4.5rem] border-b-[1px] border-[#C9B066]'>
                            <Link to="/legislacion" onClick={toggleMenu}>Ley - Legislación</Link>
                        </li> 
                        <li className='flex items-center justify-center text-[#7F5B29] h-[4.5rem] border-b-[1px] border-[#C9B066]'>
                            <Link to="/bodegas" onClick={toggleMenu}>Bodegas</Link>
                        </li> 
                        <li className='flex items-center justify-center text-[#7F5B29] h-[4.5rem]'>
                            <Link to="/noticias" onClick={toggleMenu}>Noticias</Link>
                        </li> 
                    </ul>
                </ContainerLayout>
            </MenuMobile>
        </>
    );
}

export default NavBar;
