import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import tw from 'twin.macro';
import Legislacion from './pages/Legislacion';
import NotFound from './pages/NotFound';
import Home from './pages/Home';
import NavBar from './components/nav/NavBar';
import Bodegas from './pages/Bodegas';
import News from './pages/News';
import Footer from './components/footer/Footer';
import { fetchBodegas } from './api/apiData';
import PageBodegaRender from './components/pagerender/PageBodegaRender';
import ScrollToTop from './components/ScrollToTop';
import MapsBodegas from './pages/MapsBodegas';
import ReactGA from "react-ga4";
import { ContainerLayout } from './themes/Layouts';

const MainContent = tw.div`pt-[6.2rem] md:pt-[8.3rem]`; // espacio para el NavBar

function App() {
  const [bodegasPages, setBodegasPages] = useState([]);

  useEffect(() => {
      ReactGA.initialize("G-CS3MCNW7DX");
      ReactGA.send("pageview");
  }, []);

  useEffect(() => {
      fetchBodegas().then(data => {
          setBodegasPages(data);
      });

  }, []);

  if (!bodegasPages) {
    return <ContainerLayout><div>Loading...</div></ContainerLayout> ;
  }

  return (
    <div style={{ minHeight: "100vh" }}>
      <BrowserRouter>
        <ScrollToTop />
        <NavBar/>
        <MainContent>
          <AnimatedRoutes bodegasPages={bodegasPages} />
        </MainContent>
        <Footer/>
      </BrowserRouter>
    </div>
  );
}

const AnimatedRoutes = ({ bodegasPages }) => {
  const location = useLocation();

  const pageTransition = {
    initial: { opacity: 0 },
    animate: { opacity: 1, transition: { duration: 0.5 } },
    exit: { opacity: 0, transition: { duration: 0.5 } },
  };

  return (
    <AnimatePresence mode="wait">
      <Routes location={location} key={location.pathname}>

        {bodegasPages.map((bodega, index) => (
          <Route
            key={index}
            path={`/bodegas/${bodega.slug}`}
            element={
              <motion.div
                variants={pageTransition}
                initial="initial"
                animate="animate"
                exit="exit"
              >
                <PageBodegaRender
                  imgbg={`/images/bodegas/${bodega.imgHero}`}
                  title={bodega.name}
                  review={bodega.review}
                  content={bodega.content}
                  contentimg={bodega.contentImg}
                  contactData={bodega.contact}
                  slideData={bodega.slidesImages}
                  slug={bodega.slug}
                  seo={bodega.seo}
                />
              </motion.div>
            }
          />
        ))}

        <Route path="/legislacion" element={
          <motion.div
            variants={pageTransition}
            initial="initial"
            animate="animate"
            exit="exit"
          >
            <Legislacion />
          </motion.div>
        } />

        <Route path="/bodegas" element={
          <motion.div
            variants={pageTransition}
            initial="initial"
            animate="animate"
            exit="exit"
          >
            <Bodegas />
          </motion.div>
        } />

        <Route path="/noticias" element={
          <motion.div
            variants={pageTransition}
            initial="initial"
            animate="animate"
            exit="exit"
          >
            <News />
          </motion.div>
        } />

        <Route path="/mapa" element={
          <motion.div
            variants={pageTransition}
            initial="initial"
            animate="animate"
            exit="exit"
          >
            <MapsBodegas/>
          </motion.div>
        } />

        <Route path="/" element={
          <motion.div
            variants={pageTransition}
            initial="initial"
            animate="animate"
            exit="exit"
          >
            <Home />
          </motion.div>
        } />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </AnimatePresence>
  );
};

export default App;
