import { React, useEffect, useState } from 'react';
import tw from 'twin.macro';
import Hero from '../components/hero/Hero';
import { fetchNewsLeyes } from '../api/apiData';
import CardsLeyes from '../components/cards/CardsLeyes';
import { ContainerLayout } from '../themes/Layouts';
import SEO from '../components/SEO';
import ReactGA from "react-ga4";

const Container = tw(ContainerLayout)``;

const Legislacion = () => {

    ReactGA.send({
		hitType:"pageview",
		page: "/legislacion",
		title:"Legislación Page",
	});

    const [leyes, setLeyes] = useState([]);

    useEffect(() => {
        fetchNewsLeyes().then(data => {
            setLeyes(data);
        });
    }, []);

    if (leyes.length === 0) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <SEO
                title="Vino Buenos Aires | Ley del vino" 
                description="La Ley del Vino (N°15404) es un régimen de incentivo para la producción vitivinícola bonaerense."
                name="Vino Buenos Aires."
                type="website"
            />
            <Hero 
                imgbg="./images/hero-legislacion.jpg"
                title="LEY DEL VINO"
                ley="Ley N°15404"
            />

            <Container className='flex flex-col gap-[6.5rem] border-s-[0.1rem] border-[#C9B066] mb-[8rem]'>
                {leyes.map((ley, index) => (
                    <CardsLeyes
                        key={index}
                        title={ley.title}
                        detail={ley.detail}
                        img={ley.img}
                        />
                ))}
            </Container>

        </>
    );
}

export default Legislacion;
