import React from 'react';
import tw from 'twin.macro';
import ShareButtons from '../ShareButtons';
import { motion } from 'framer-motion';

// const Card = tw.article`relative flex flex-col md:flex-row px-[1.5rem] md:px-[4.4rem]`;
const Card = tw(motion.article)`relative grid grid-cols-4 md:grid-cols-12 px-[1.5rem] md:px-[4.4rem]`;
const Title = tw.h2`capitalize text-[#B47D02] font-bold text-[1.6rem] leading-[2rem] mb-[1.5rem] md:text-[4rem] md:leading-[4.4rem] md:mb-[1.5rem]`;
const Detail = tw.p`text-[#373737] text-[1.3rem] leading-[1.5rem] md:text-[1.4rem] md:leading-[1.8rem] mb-[1.5rem]`;
const ImgCard  = tw.img`rounded-[0.4rem] mb-[1rem] md:mb-[2.5rem]`;
const SharedContainer  = tw.div`flex items-center text-[#373737] font-medium text-[1.3rem] md:text-[1.6rem]`;
const Circle = tw.div`absolute bg-[#C9B066] h-[0.6rem] w-[0.6rem] md:h-[1rem] md:w-[1rem] rounded-full -translate-y-[50%] left-[0] top-[1rem] md:top-[2.5rem] -translate-x-[50%] z-10`;

const CardsLeyes = ({ title, detail, img }) => {
    return (
        <Card
            initial={{ opacity: 0, y: 100 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
        >
            <Circle />
            <div className='col-span-4 md:col-span-6'>
                <Title>{title}</Title>
                <Detail>{detail}</Detail>
            </div>
            <div className='col-span-4 md:col-span-4 md:col-start-8'>
                <ImgCard
                    className='w-full'
                    src={`/images/leyes/${img}`}
                    alt={title}
                />
                <SharedContainer>
                    <span>Compartir en</span><ShareButtons title={title} shareUrl="https://www.vinoba.com.ar/legislacion" />
                </SharedContainer>
            </div>
        </Card>
    );
};

export default CardsLeyes;
