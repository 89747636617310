import React from 'react'
import tw from 'twin.macro'

const Card = tw.div`
    flex flex-col justify-between items-center h-[45rem] bg-white rounded-[0.4rem] shadow-md
    font-semibold text-center text-[#373737] text-[1.8rem]
    my-[1rem] mx-[0.2rem]
    pt-[5rem] pb-[4rem] px-[2rem]
    md:px-[3rem]`;
const Comillas = tw.img`w-[4rem] md:w-[4.5rem]`;
const Msj = tw.p`font-semibold text-[2.2rem]`;
const Line = tw.div`h-[1px] w-[16rem] bg-[#B47D02] mb-[3rem]`;

const CardQuote = ({ story, name, bodega }) => {
    return (
        <Card>
            <Comillas src='./images/comillas.svg' alt='icono de comillas' />
            <Msj>{story}</Msj>
            <div className='flex flex-col justify-center items-center'>
                <Line/>
                <span className='text-[1.6rem] font-semibold'>{name}</span>
                <span className='text-[1.4rem] font-medium'>{bodega}</span>
            </div>
        </Card>
    )
}

export default CardQuote