import React from 'react'
import tw from 'twin.macro';
import { ContainerLayout } from '../themes/Layouts'
import SEO from '../components/SEO';
import ReactGA from "react-ga4";

const Title = tw.h1`font-bold text-[2.2rem] leading-[2.4rem] mb-[2.5rem] text-[#A57C0E]`;
const MapaIframe = tw.iframe`h-[60vh] md:h-[70vh] w-full rounded-[0.4rem]`;

const MapsBodegas = () => {

    

    ReactGA.send({
		hitType:"pageview",
		page: "/mapa",
		title:"Mapa Page",
	});

    return (
        <>
            <SEO
                title="Vino Buenos Aires | Mapa" 
                description="Explorá el mapa con los productores de la provincia"
                name="Vino Buenos Aires."
                type="website"
            />
            <ContainerLayout className='mt-[6rem] md:mt-[8.5rem] min-h-[80vh]'>
                <Title style={{ fontFamily: "Aoboshi One !important" }}>Explorá el mapa con los productores de la provincia</Title>
                <MapaIframe src="https://www.google.com/maps/d/u/6/embed?mid=16u0-1VE0EewqBRR_EiZXpPXO-LDhDIc&ehbc=2E312F"/>
            </ContainerLayout>
        </>
    )
}

export default MapsBodegas