import React, { useRef } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/pagination'; // Importa estilos de paginación

import './styles-quotes.css';

// Import Swiper modules
import { Autoplay, Pagination } from 'swiper/modules';
import CardQuote from '../cards/CardQuote';

const stories = [
    {
        story: "Las bodegas más grandes de Argentina están trabajando en la provincia de Buenos Aires con proyectos serios.",
        name: "Marcelo Chocarro ",
        bodega: "Bodega Gamboa",
    },
    {
        story: "Somos productores vitivinícolas de toda la vida y con la Ley todo el mundo del Vino volvió a crecer.",
        name: "Federico Sottano",
        bodega: "Productor Viñatero",
    },
    {
        story: "El vino es algo muy importante en mi vida, tiene una historia y una cultura de encuentro. Lo elaboro para compartirlo con amigos.",
        name: "Alejandro Lurisci",
        bodega: "Elaborador de Vinos",
    },
    {
        story: "Con la Ley de Vino Buenos Aires, comenzamos una nueva etapa en la Industria Vitivinícola siendo los productores más visibilizados.",
        name: "Eduardo Tuite",
        bodega: "fundador de Bodegas Gamboa",
    },
    {
        story: "Es necesario que el Estado contribuya al crecimiento y desarrollo del sector.",
        name: "Manuela Parra",
        bodega: "Bodega Saldungaray",
    },
    {
        story: "La Ley es una buena herramienta para el desarrollo de lo que venimos haciendo. Lo importante es la difusión de la calidad de nuestros vinos.",
        name: "Daniel Di Nucci",
        bodega: "Bodega al Este",
    },
    {
        story: "Con incentivos del Estado, la producción vitivinícola va a explotar. Además, el Enoturismo es un encuentro con la vitivinicultura bonaerense.",
        name: "Carlos María Bertola",
        bodega: "Bodega MYL Colores",
    },
]

const SliderQuotes = () => {
    // Usa un ref para almacenar la instancia de Swiper
    const swiperRef = useRef(null);

    return (
        <div className="relative">
            <Swiper
                className='mySwiperQuotes'
                spaceBetween={50}
                loop={true} // Activa el loop para que sea infinito
                autoplay={{
                    delay: 3000, // Tiempo en milisegundos entre cada slide
                    disableOnInteraction: false, // Autoplay continúa tras la interacción
                }}
                pagination={{ clickable: true }} // Habilita la paginación
                modules={[Autoplay, Pagination]} // Incluye el módulo de paginación
                breakpoints={{
                    0: {
                        slidesPerView: 1,
                    },
                    768: {
                        slidesPerView: 3,
                        spaceBetween: 30,
                    }
                }}
                onSwiper={(swiper) => {
                    swiperRef.current = swiper; // Guarda la referencia al swiper
                }}
            >

                {stories.map((story, index) => (
                    <SwiperSlide key={index}>
                        <CardQuote
                            key={index}
                            story={story.story}
                            name={story.name}
                            bodega={story.bodega}
                            />
                    </SwiperSlide>
                ))}

            </Swiper>

            {/* Botón Prev, oculto en mobile y visible en md en adelante */}
            <button 
                className='hidden md:block absolute -left-[50px] top-1/2 transform -translate-y-1/2 z-10'
                onClick={() => swiperRef.current.slidePrev()}
            >
                <img src='/images/icon-slider-prev.svg' alt="Prev"/>
            </button>

            {/* Botón Next, oculto en mobile y visible en md en adelante */}
            <button 
                className='hidden md:block absolute -right-[50px] top-1/2 transform -translate-y-1/2 z-10'
                onClick={() => swiperRef.current.slideNext()}
            >
                <img src='/images/icon-slider-next.svg' alt="Next"/>
            </button>
        </div>
    );
}

export default SliderQuotes;
