import React, { useState, useEffect } from 'react';
import tw from 'twin.macro';
import Hero from '../components/hero/Hero';
import { ContainerLayout } from '../themes/Layouts';
import { fetchNews } from '../api/apiData';
import CardNewHome from '../components/cards/CardNewHome';
import Separator from '../components/section/Separator';
import CardNewPrimary from '../components/cards/CardNewPrimary';
import ButtonOutLine from '../components/buttons/ButtonOutLine';
import ButtonLoadMoreAnimate from '../components/buttons/ButtonLoadMoreAnimate';
import SEO from '../components/SEO';
import ReactGA from "react-ga4";

const Container = tw(ContainerLayout)`grid grid-cols-4 md:grid-cols-12`;

const News = () => {

    ReactGA.send({
		hitType:"pageview",
		page: "/noticias",
		title:"Noticias Page",
	});

    const [news, setNews] = useState([]);
    const [visibleNewsCount, setVisibleNewsCount] = useState(7); // Inicialmente mostramos 7 noticias
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchNews().then(data => {
            setNews(data);
        });
    }, []);

    const loadMoreNews = () => {
        setLoading(true);
        setTimeout(() => {
            setVisibleNewsCount(prevCount => prevCount + 3); // Muestra 3 más cada vez que se hace clic
            setLoading(false);
        }, 500); // Simulación de carga
    };

    return (
        <>
            <SEO
                title="Vino Buenos Aires | Noticias" 
                description="Las principales noticias sobre el mundo de la vitivinicultura de Buenos Aires."
                name="Vino Buenos Aires."    
                type="website"
            />
            <Hero imgbg="./images/hero-news.jpg" title="NOTICIAS" />
            
            {news.filter(item => item.primary).map(item => (
                <Container key={item.id}> {/* Usa item.id como key */}
                    <div className='col-span-4 md:col-span-12 mb-[3rem] md:mb-[8rem]'>
                        <CardNewPrimary
                            title={item.title}
                            date={item.date}
                            detail={item.detail}
                            img={`./images/news/${item.img}`}
                            linkText={item.link.text}
                            url={item.link.url}
                        />
                    </div>
                    <Separator />
                </Container>
            ))}

            <Container className='gap-[4rem] pt-[6rem] mb-[8rem] md:mb-[10rem]'>
                {news.slice(0, visibleNewsCount).map((item, index) => (
                    !item.primary && (
                        <div className='col-span-4' key={item.id}> {/* Usa item.id como key */}
                            <CardNewHome
                                img={`./images/news/${item.img}`}
                                title={item.title}
                                linkText={item.link.text}
                                url={item.link.url}
                            />
                        </div>
                    )
                ))}
            </Container>

            {visibleNewsCount < news.length && (
                <div className="text-center mb-[8rem] md:mb-[10rem]">
                    {/* <ButtonOutLine
                        text={loading ? "Cargando..." : "Ver más noticias"}
                        textcolor="#C9B066"
                        bgcolor="white"
                        onClick={loadMoreNews} // Asegúrate de pasar la función aquí
                        disabled={loading} // Deshabilitar el botón durante la carga
                    /> */}
                    <ButtonLoadMoreAnimate
                        text={loading ? "Cargando" : "Ver más noticias"}
                        onClick={loadMoreNews}
                        disabled={loading} // Deshabilitar el botón durante la carga
                    />
                </div>
            )}
        </>
    );
}

export default News;